import { useLocalStorage } from '@uidotdev/usehooks';
import { useEffect } from 'react';
import { logoutUser } from './httpCalls';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

function useAuth() {
  const [role, setRole] = useLocalStorage('role', '');
  const [token, setToken] = useLocalStorage('token', '');
  const navigate = useNavigate();

  // Check if token is expired
  const isTokenExpired = () => {
    // console.log('inside isTokenExpired');
    if (!token) return true; // No token = treat as expired
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      return decodedToken.exp < currentTime;
    } catch (error) {
      console.error('Invalid token:', error);
      return true; // If the token is invalid, treat as expired
    }
  };

  const logout = async () => {
    // If token is expired, just clean up locally
    if (isTokenExpired()) {
      //console.log('inside logout isTokenExpired');
      await Promise.all([
        localStorage.removeItem('token'),
        setToken(null),
        setRole(''),
      ]);

      navigate('/');
      return;
    }

    try {
      //console.log('inside logout try');
      const decodedToken = jwtDecode(token);
      await Promise.all([
        await logoutUser(decodedToken?.sub),
        localStorage.removeItem('token'),
        setToken(null),
        setRole(''),
      ]);
      navigate('/');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const getRole = () => {
    if (isTokenExpired()) return ''; // Return empty role if token expired
    if (!token) return '';
    try {
      const decodedToken = jwtDecode(token);
      return decodedToken?.is_admin ? 'admin' : 'user';
    } catch (error) {
      console.error('Invalid token:', error);
      return '';
    }
  };

  const getUser = () => {
    if (isTokenExpired()) return null;
    if (!token) return null;
    try {
      return jwtDecode(token);
    } catch (error) {
      console.error('Invalid token:', error);
      return null;
    }
  };

  // Automatically logout when token expires
  useEffect(() => {
    // console.log('Automatically logout when token expires');
    if (!token) return;

    if (isTokenExpired()) {
      logout();
    }
  }, [token]); // Only run when token changes

  return {
    role,
    setRole,
    logout,
    getRole,
    token,
    setToken,
    getUser,
  };
}

export default useAuth;
