import { Box, Typography } from "@mui/material";

const ProtectedRouteViolation = () => {
  return (
    <>
      <Box
        sx={{
          maxWidth: "42rem",
          fontSize: "3rem",
          lineHeight: "1.25",
          mh: "3rem",
          fontWeight: "700",
        }}
      >
        <Typography sx={{ my: 4 }} variant="h2">
        Δεν έχετε πρόσβαση στο περιεχόμενο αυτής της σελίδας.
        </Typography>
      </Box>
      <Typography sx={{ my: 4 }}>
      Δοκιμάστε ξανά να συνδεθείτε.
      </Typography>
    </>
  );
};
export default ProtectedRouteViolation;
