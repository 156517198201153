import React, { useEffect, useMemo } from "react";
import { taxisAdminLogout, taxisLogout } from "../../Services/taxisnet";
import useAuth from "../../Services/useAuth";

const RedirectHandler = () => {
  const { getRole, logout } = useAuth();

  // Memoize logout function to avoid re-creating it on every render
  const logoutFunction = useMemo(() => {
    return getRole() === "admin" ? taxisAdminLogout : taxisLogout;
  }, [getRole]);

  useEffect(() => {
    const logoutTimeout = setTimeout(async () => {
      try {
        await logoutFunction();
        logout();
      } catch (error) {
        console.log(error);
      }
    }, 3000);

    // Clean up the timeout to prevent memory leaks
    return () => clearTimeout(logoutTimeout);
  }, []);

  return (
    <div>
      <h1>Έξοδος...</h1>
      <p>Θα ανακατευθυνθείτε σύντομα.</p>
    </div>
  );
};

export default RedirectHandler;
