import { Box, Typography } from "@mui/material";
import { taxisRedirect } from "../../Services/taxisnet";
import RoundedButton from "../../UIComponents/Buttons/RoundedButton";

const UserLoginConfirmationPage = () => {
  const handleConfirm = () => {
    taxisRedirect();
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        mt: 3,
        mx: 8,
        mb: 10,
        gap: 4,
      }}
    >
      <Typography variant="h2">
        Συνδεθείτε κάνοντας χρήση των κωδικών taxisnet
      </Typography>
      <Typography>
        Για να εισέλθετε στο σύστημα, θα πρέπει να συνδεθείτε με τους
        προσωπικούς κωδικούς taxisnet (username & password)
      </Typography>
      Για το σκοπό αυτό, θα μεταφερθείτε προσωρινά στο ασφαλές περιβάλλον της
      Γενικής Γραμματείας Πληροφοριακών Συστημάτων Δημόσιας Διοίκησης για να
      κάνετε τη σύνδεση.
      <Typography>
        Συνεχίζοντας επιβεβαιώνετε ότι έχετε διαβάσει και συμφωνείτε με την
        πολιτική απορρήτου, τον Οδηγό Εφαρμογής του Προγράμματος και την
        πολιτική cookies.
      </Typography>
      <RoundedButton variant="contained" color="link" onClick={handleConfirm}>
        Είσοδος με διαπιστευτήρια Taxisnet
      </RoundedButton>
    </Box>
  );
};

export default UserLoginConfirmationPage;
