import { Box, Typography } from '@mui/material';
import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDeleteFile } from '../Services/useDeleteFile';
import RoundedButton from '../UIComponents/Buttons/RoundedButton';
import DeleteModal from '../UIComponents/Modals/DeleteModal';
import { useUploadFile } from '../Services/useUploadFile';
import { getFileUrl } from '../Services/httpCalls';
import { TextFieldElement } from 'react-hook-form-mui';
import Loader from '../UIComponents/Loader';
import { getIsFileUploaded } from '../Services/httpCalls';
import { enqueueSnackbar } from 'notistack';

const SingleFileUploader = ({
  id,
  reqId,
  profId,
  savedFile,
  setFileErrors,
  setFiles,
  setFileNames,
  index,
  oldFile,
}) => {
  const [selectedFile, setSelectedFile] = useState(savedFile || null);
  const [fileURL, setFileURL] = useState();
  const [isLinkVisible, setIsLinkVisible] = useState(false);
  const [isShownModal, setIsShownModal] = useState(false);
  const fileInputRef = useRef(null);
  const [hasFileChanged, setHasFileChanged] = useState(false);
  const { mutateAsync: deleteFile } = useDeleteFile();
  const { mutateAsync: uploadFile } = useUploadFile();
  const [isFileError, setIsFileError] = useState({
    [profId]: savedFile?.name === null || savedFile === null,
  });
  const [isfileSelectDisabled, setIsFileSelectDisable] = useState(false);

  const getIfFileExist = async () => {
    try {
      const fileUrl = await getFileUrl(
        `${reqId}/${profId}`,
        selectedFile.name.replace(/\.pdf$/i, '')
      );
      setFileURL(fileUrl?.data.url);
    } catch (error) {
      console.error('Error checking file existence:', error);
    }
  };

  const isFileExist = async (url) => {
    let fileExist = false;
    try {
      const res = await getIsFileUploaded(url);
      fileExist = res.data?.is_file_exist;
      return fileExist;
    } catch (err) {
      return false;
    }
  };

  const initializeOldFiles = () => {
    if (oldFile) {
      setFiles((prevState) => [...prevState, oldFile]);
      setFileNames((prevState) => ({
        ...prevState,
        [index]: oldFile.name,
      }));
      setSelectedFile(oldFile);
    }
  };

  useEffect(() => {
    getIfFileExist();
    initializeOldFiles();
    if (savedFile) {
      setSelectedFile(savedFile);
    }
  }, [savedFile]);
  useEffect(() => {
    setFileErrors((prevState) => ({
      ...prevState,
      [profId]: savedFile?.name === null || savedFile === null,
    }));
    // Delay the appearance of the Link by 7 seconds
    const timer = setTimeout(() => {
      setIsLinkVisible(true);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);
  const handleUploadFile = async () => {
    if (!selectedFile) return;
    if (!reqId) {
      setHasFileChanged(false);
      setIsFileError({ [profId]: false });
      setFiles((prevState) => [...prevState, selectedFile]);
      setFileNames((prevState) => ({
        ...prevState,
        [index]: selectedFile.name,
      }));
      setFileErrors((prevState) => ({ ...prevState, [profId]: false }));
    }
    if (reqId && profId) {
      const formFileData = new FormData();
      formFileData.append('file', selectedFile);
      formFileData.append('request_id', reqId);
      formFileData.append('profession_id', profId);

      try {
        const isFileExistance = await isFileExist(`${reqId}/${profId}`);
        if (isFileExistance) {
          enqueueSnackbar(
            'Εχετε ήδη ανεβάσει τίτλο σπουδών σε αυτό το επάγγελμα',
            {
              variant: 'error',
              autoHideDuration: 2000,
            }
          );
        } else {
          const res = await uploadFile(formFileData);

          if (res.status === 201) {
            isSelectFileDisable(true);
          } else {
            isSelectFileDisable(false);
          }
          setHasFileChanged(false);

          getIfFileExist();
        }
        setIsFileError({ [profId]: false });
        setFileErrors((prevState) => ({ ...prevState, [profId]: false }));
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleFileChange = (e) => {
    const fileToUpload = e.target.files[0];
    if (fileToUpload) {
      const fileType = fileToUpload.type;
      if (fileType === 'application/pdf') {
        setSelectedFile(fileToUpload);
        setHasFileChanged(true);
      } else {
        alert('Παρακαλώ επιλέξτε αρχείο τύπου PDF.');
      }
    }
  };

  const handleDeleteFile = async () => {
    if (selectedFile) {
      try {
        const fileNameWithoutExtension = selectedFile.name.replace(
          /\.pdf$/i,
          ''
        );
        await deleteFile({
          dir: `${reqId}/${profId}`,
          file_name: fileNameWithoutExtension,
        });
        setSelectedFile(null);
        setHasFileChanged(false);
        setIsShownModal(false);
        setIsFileError({ [profId]: true });
        setFileErrors((prevState) => ({ ...prevState, [profId]: true }));
        getIfFileExist();
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const isSelectFileDisable = (fileUpload) => {
    if (fileUpload) return true;

    let isDisabled = false;
    if (id && (!selectedFile || !fileURL || (!fileUpload && hasFileChanged))) {
      isDisabled = false;
    } else {
      isDisabled = true;
    }

    return isDisabled;
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <Typography
          variant='h4'
          name={id}
          sx={{
            color: 'primary',
            fontWeight: 'bold',
            mr: 4,
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}
          aria-label='file'
        >
          {selectedFile?.name ? (
            <>
              <span>Επιλεγμένο αρχείο:</span>
              {isLinkVisible ? (
                <Typography sx={{ ml: 2 }}>{selectedFile.name}</Typography>
              ) : (
                <Box sx={{ height: '50px' }}>
                  <Loader />
                </Box>
              )}
            </>
          ) : (
            'Δεν επιλέχθηκε κανένα αρχείο.'
          )}
        </Typography>
        <RoundedButton
          color='successLight'
          variant='contained'
          disabled={!hasFileChanged}
          onClick={handleUploadFile}
          sx={{ py: 0.2, mr: '14px' }}
        >
          Ανέβασμα Αρχείου
        </RoundedButton>
        {reqId && (
          <RoundedButton
            onClick={() => setIsShownModal(true)}
            color='error'
            variant='contained'
            sx={{ mr: '14px', py: 0.2 }}
            disabled={isFileError[profId]}
          >
            Διαγραφή Αρχείου
          </RoundedButton>
        )}
        <Box sx={{ width: '155px' }}>
          <input
            type='file'
            id={id}
            name={id}
            aria-label='file'
            ref={fileInputRef}
            onChange={handleFileChange}
            style={{
              display: 'none',
            }}
          />
          <TextFieldElement
            value={selectedFile?.name || ''}
            name={id}
            style={{
              display: 'none',
            }}
            id={id}
          >
            {id}
          </TextFieldElement>
          <RoundedButton
            disabled={isSelectFileDisable(false)}
            onClick={() => fileInputRef.current.click()}
            color='link'
            variant='contained'
            sx={{
              width: '100%',
              py: 0.2,
            }}
          >
            Επιλέξτε Αρχείο
          </RoundedButton>
        </Box>
        {isShownModal && (
          <DeleteModal
            title='Είστε σίγουρος ότι θέλετε να διαγράψετε το αρχείο;'
            setIsShownModal={setIsShownModal}
            onDelete={handleDeleteFile}
          />
        )}
      </Box>

      {isFileError[profId] && (
        <Typography sx={{ color: 'red' }}>
          Απαιτείται προσκόμιση πτυχίου αν έχετε επιλέξει τίτλος σπουδών ως
          τεκμηρίωση επαγγέλματος
        </Typography>
      )}
    </Box>
  );
};

export default SingleFileUploader;
