import { useQuery } from "@tanstack/react-query";
import { getDegrees } from "./httpCalls";
import { enqueueSnackbar } from "notistack";

export function useGetDegrees() {
    return useQuery({
        queryFn: () => getDegrees(),
        queryKey: [`get_degrees`],
        onSuccess: () => {
            enqueueSnackbar('????????', { variant: 'success', autoHideDuration:2000 });
          },
          onError: (error) => {
            enqueueSnackbar(error?.response?.data?.message || '????????', { variant: 'error' , autoHideDuration:2000});
          }
    });
}
