import { dateFormat } from '../helperFuncs';
import HttpService from './environment';
import axios from 'axios';

export const postEmail = (email) =>
  HttpService.getAxiosClient().put('email', email);

export const postGroupList = (groups) =>
  HttpService.getAxiosClient().post('wp/list', { categories: groups });

export const postGroup = (group) =>
  HttpService.getAxiosClient().post('newsletter/categories', {
    category: group,
  });
export const getIsFileUploaded = (dir) => {
  return HttpService.getAxiosClient().get('file/exist', {
    params: { dir },
  });
};
export const getFileUrl = (dir, file_name) => {
  return HttpService.getAxiosClient().get('file', {
    params: { dir, file_name },
  });
};
export const otpConfirm = (otp) =>
  HttpService.getAxiosClient().put('otp/confirmation', otp);
export const getOtp = () => HttpService.getAxiosClient().put('otp/new');
export const getJobs = () => HttpService.getAxiosClient().get('professions');
export const logoutUser = (userId) =>
  HttpService.getAxiosClient().put('logout', { user_id: userId });
export const getDegrees = () =>
  HttpService.getAxiosClient().get('professions/proofs');
export const isConsent = (consent) =>
  HttpService.getAxiosClient().put('certification', {
    check_kad_certification: consent,
  });
export const getMunicipalities = () =>
  HttpService.getAxiosClient().get('municipalities');

export const getGroups = () =>
  HttpService.getAxiosClient().get('newsletter/categories');
export const getActiveGroups = () =>
  HttpService.getAxiosClient().get('newsletter/categories', {
    params: { is_active: 1 },
  });
export const getUser = (afm) =>
  HttpService.getAxiosClient().get('user', { params: { afm } });

export const getUserData = (afm) =>
  HttpService.getAxiosClient().get('user', { params: { afm } });

export const getHistory = (id) =>
  HttpService.getAxiosClient().get(`requests/${id}/history`);

export const getGroupsManagement = () =>
  HttpService.getAxiosClient().get('groups-management');

export const deleteFile = (file) =>
  HttpService.getAxiosClient().delete('file', {
    data: file,
  });

export const uploadFile = (file) =>
  HttpService.getAxiosClient().put('file', file);

export const deactivateUser = (id) =>
  HttpService.getAxiosClient().patch(`user/activation/${id}`, {
    is_active: false,
  });

export const activateUser = (id) =>
  HttpService.getAxiosClient().patch(`user/activation/${id}`, {
    is_active: true,
  });

export const deactivateGroup = (id) =>
  HttpService.getAxiosClient().patch(`newsletter/categories/activation/${id}`, {
    is_active: false,
  });

export const activateGroup = (id) =>
  HttpService.getAxiosClient().patch(`newsletter/categories/activation/${id}`, {
    is_active: true,
  });

export const getRequestByAfm = (afm) =>
  HttpService.getAxiosClient().get('requests', { params: { afm } });
export const getRequestById = (id) =>
  HttpService.getAxiosClient().get('requests', { params: { id } });
export const getFilteredRequests = (params) =>
  HttpService.getAxiosClient().get('requests', {
    params: {
      first_name: params.name,
      last_name: params.surname,
      email: params.email,
      creation_date: dateFormat(params.date),
      groups: params.category_id,
      municipalities: params?.spatialActivity?.map(
        (activity) => activity.value
      ),
      professions: params.job,
      is_certified:
        params?.is_certified === 1 ? 1 : params?.is_certified === 2 ? 0 : null,
    },
  });
export const getFiltereQuestions = (params) =>
  HttpService.getAxiosClient().get('questions', {
    params: {
      date: params.date,
      answer_date: dateFormat(params.answer_date),
      afm: params.afm,
      category_ids: params.category_id,
      first_name: params.name,
      last_name: params.surname,
      subject: params.subject,
      is_answered: params.is_answered,
    },
  });
export const postNewRequest = (formData) =>
  HttpService.getAxiosClient().post('requests', formData, {
    headers: {
      'Content-Type': 'multipart/form-data; charset=utf-8',
    },
  });
export const updateRequest = (data, id) =>
  HttpService.getAxiosClient().put(`requests/${id}`, data);

export const postNewQuestion = (data) =>
  HttpService.getAxiosClient().post('questions', data);

export const putAnswer = (data) =>
  HttpService.getAxiosClient().put(`questions/${data.question_id}/answer`, {
    answer: data.answer,
  });

export const getFileDetails = (id) =>
  HttpService.getAxiosClient().get(`file-details/${id}`);

export const getQuestions = () => HttpService.getAxiosClient().get('questions');

export const getGroupsByUserId = (userId) =>
  HttpService.getAxiosClient().get(`/newsletter/user/${userId}`);
export const getQuestionById = (id) =>
  HttpService.getAxiosClient().get('questions', {
    params: {
      id,
    },
  });
export const getQuestionByUserId = (id) =>
  HttpService.getAxiosClient().get('questions', {
    params: {
      user_id: id,
    },
  });
export const getAllRequests = () =>
  HttpService.getAxiosClient().get(`requests`);

export const getRequest = (id) =>
  HttpService.getAxiosClient().get(`requests/${id}`);

export const getOldRequest = (id) =>
  HttpService.getAxiosClient().get(`requests/old`, {
    params: {
      afm: id,
    },
  });
export const getOldRequestById = (id) =>
  HttpService.getAxiosClient().get(`requests/old`, {
    params: {
      id,
    },
  });
export const updateUserCommunicationGroups = (data) => {
  HttpService.getAxiosClient().post('newsletter/edit', data);
};

export const updateGroup = (id, category) =>
  HttpService.getAxiosClient().put('newsletter/categories', { id, category });

export const getErganiEmploymentHistory = async () => {
  const token = localStorage.getItem('token');
  try {
    return await axios({
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token.replace(/"/g, '')}`,
      },
      method: 'GET',
      baseURL: process.env.REACT_APP_BACKEND_URL,
      url: '/gsis/employment/ergani/history',
      params: null,
      data: null,
    }).then((res) => res.data);
  } catch (err) {
    console.error(err);
  }
};

export const getEfkaEmployment = async () => {
  const token = localStorage.getItem('token');
  try {
    return await axios({
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token.replace(/"/g, '')}`,
      },
      method: 'GET',
      baseURL: process.env.REACT_APP_BACKEND_URL,
      url: '/gsis/employment/efka',
      params: null,
      data: null,
    }).then((res) => res.data);
  } catch (err) {
    console.error(err);
  }
};

export const downloadFile = async (request_id, profession_id, file_name) => {
  const token = localStorage.getItem('token');
  try {
    return await axios({
      headers: {
        'Content-Type': 'application/pdf',
        Authorization: `Bearer ${token.replace(/"/g, '')}`,
      },
      method: 'GET',
      baseURL: process.env.REACT_APP_BACKEND_URL,
      url: '/file/download',
      params: {
        request_id: request_id,
        profession_id: profession_id,
        file_name: file_name,
      },
      data: null,
      responseType: 'blob',
    })
      .then((response) => response.data)
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${file_name}.pdf`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  } catch (err) {
    console.error(err);
  }
};

export const downloadStaticFile = async (file_name) => {
  const token = localStorage.getItem('token');
  try {
    return await axios({
      headers: {
        'Content-Type': 'text/csv',
        Authorization: `Bearer ${token.replace(/"/g, '')}`,
      },
      method: 'GET',
      baseURL: process.env.REACT_APP_BACKEND_URL,
      url: '/file/download/static',
      params: {
        file_name: file_name,
      },
      data: null,
      responseType: 'blob',
    })
      .then((response) => response.data)
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${file_name}`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  } catch (err) {
    console.error(err);
  }
};

export const getUsersData = async (params) => {
  const token = localStorage.getItem('token');
  try {
    return await axios({
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token.replace(/"/g, '')}`,
      },
      method: 'GET',
      baseURL: process.env.REACT_APP_BACKEND_URL,
      url: '/user',
      params: params,
      data: null,
    }).then((res) => res.data);
  } catch (err) {
    console.error(err);
  }
};
