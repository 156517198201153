import { useEffect } from "react";
import useAuth from "../Services/useAuth";
import { useNavigate } from "react-router-dom";

const ProtectedRoute = ({ children, isAdmin }) => {
  const { getUser } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    if ((+getUser()?.is_admin !== +isAdmin)) {
      navigate("/route-violation");
    }
  }, [isAdmin, getUser, navigate]);
  return children;
};

export default ProtectedRoute;
