import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Typography, Divider } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  FormContainer,
  MultiSelectElement,
  TextFieldElement,
} from 'react-hook-form-mui';
import { useNavigate, useParams } from 'react-router-dom';
import { getQuestionById, getUsersData } from '../../Services/httpCalls';
import useAuth from '../../Services/useAuth';
import { useGetActiveGroups } from '../../Services/useGetActiveGroups';
import { usePostNewQuestion } from '../../Services/usePostNewQuestion';
import { usePutAnswer } from '../../Services/usePutAnswer';
import RoundedButton from '../../UIComponents/Buttons/RoundedButton';
import GovWrapper from '../../UIComponents/GovWrapper';
import Loader from '../../UIComponents/Loader';
import { newQuestionDefaultValues } from '../../helperFuncs';
import { answerValidation, questionValidation } from './questionValidation';
import { useWindowSize } from '@uidotdev/usehooks';

const NewQuestion = () => {
  const { id } = useParams();
  const [isAnswered, setIsAnswered] = useState();
  const [formData, setFormData] = useState();
  const [isReseting, setIsReseting] = useState(false);
  const { getRole, getUser } = useAuth();
  const [questionData, setQuestionData] = useState({
    first_name: '',
    last_name: '',
    creation_date: '',
  });
  const [answerFrom, setAnswerFrom] = useState('');

  const navigate = useNavigate();
  const { mutateAsync: postQuestion, isSuccess: isQuestionSuccess } =
    usePostNewQuestion(formData);
  const { mutateAsync: updateAnswer, isSuccess: isAnswerSuccess } =
    usePutAnswer({
      answer: formData?.answer,
      question_id: id,
    });
  const { data: questionGroups, isLoading } = useGetActiveGroups();
  const mappedGroups = questionGroups?.data?.categories?.map((item) => ({
    id: item.id,
    label: item.category,
  }));

  const handleClick = async (data) => {
    const { answer, ...questionData } = data;
    getRole() === 'admin' ? setFormData(data) : setFormData(questionData);
    getRole() === 'admin' ? await updateAnswer() : await postQuestion();
  };

  useEffect(() => {
    if (isQuestionSuccess) {
      enqueueSnackbar('Το ερώτημά σας στάλθηκε', {
        variant: 'success',
        autoHideDuration: 2000,
      });
      navigate('/questions');
    }
    if (isAnswerSuccess) {
      enqueueSnackbar('Η απάντηση στάλθηκε', {
        variant: 'success',
        autoHideDuration: 2000,
      });
      navigate('/questions-management');
    }
  }, [isQuestionSuccess, isAnswerSuccess, navigate]);

  const formContext = useForm({
    resolver:
      getRole() === 'admin'
        ? yupResolver(answerValidation)
        : yupResolver(questionValidation),
    defaultValues: newQuestionDefaultValues,
  });

  useEffect(() => {
    if (!isReseting) {
      const textareas = document.querySelectorAll('textarea');

      textareas.forEach((textarea, index) => {
        if (textarea.hasAttribute('aria-hidden')) {
          // Set an accessible label for each textarea for accessibility purposes
          textarea.setAttribute(
            'aria-label',
            `Description of textarea ${index + 1}`
          );
          textarea.removeAttribute('aria-hidden');
        }
      });
    }

    // By not depending on isReseting directly here, we ensure the effect doesn't re-trigger unnecessarily
  }, [isReseting]); // This will only run when `isReseting` changes

  useEffect(() => {
    const fetchQuestion = async () => {
      setIsReseting(true);
      const resp = await getQuestionById(id);
      const question = resp?.data?.questions[0];
      setQuestionData({
        creation_date: question?.creation_date,
        first_name: question?.first_name,
        last_name: question?.last_name,
        afm: question?.afm,
      });
      if (question?.afm !== getUser()?.afm && getRole() !== 'admin') {
        navigate('/route-violation');
      }
      setIsAnswered(question?.answer);

      const user = await getUsersData({
        user_id: question?.answer_user_id,
      });

      setAnswerFrom(`${user?.user?.first_name} ${user?.user?.last_name}`);
      formContext.reset({
        subject: question?.subject,
        category_ids: question?.question_categories?.map(
          (item) => item.category_id
        ),
        description: question?.description,
        answer: question?.answer,
      });
      setIsReseting(false);
    };

    id && fetchQuestion();
  }, [id]);

  const TitleQuestion = () => {
    if (!id) {
      return <Typography variant='h2'>Νέο ερώτημα</Typography>;
    } else if (id && getRole() === 'admin') {
      return <Typography variant='h2'>Ερώτημα χρήστη</Typography>;
    } else if (id && getRole() !== 'admin') {
      return <Typography variant='h2'>To ερώτημα μου</Typography>;
    } else {
      return <></>;
    }
  };

  const DetailRow = ({ label, value }) => (
    <>
      <Box
        display='grid'
        gridTemplateColumns={'400px 1fr'}
        alignItems='center'
        my={1}
      >
        <Typography fontWeight={600}>{label}</Typography>
        <Typography>{value}</Typography>
      </Box>
      <Divider />
    </>
  );

  const SubtitleQuestion = () => {
    if (!id) {
      return (
        <Typography>
          Συμπληρώστε όλα τα πεδία για να προχωρήσετε στην καταχώριση
        </Typography>
      );
    } else if (id && getRole() === 'admin') {
      return (
        <Box>
          <Typography mb={2}>
            Διαχειριστείτε και απαντήστε στα ερωτήματα των εξωτερικών χρηστών
            σχετικά με το Mητρώο Απασχολούμενων στον Πολιτιστικό και Δημιουργικό
            Τομέα.
          </Typography>

          <Typography mb={2}>
            Τα στοιχεία του χρήστη που υπέβαλε το ερώτημα:{' '}
          </Typography>

          <DetailRow label={'Oνομα:'} value={questionData?.first_name} />
          <DetailRow label={'Επώνυμο:'} value={questionData?.last_name} />
          <DetailRow label={'ΑΦΜ:'} value={questionData?.afm} />
          <DetailRow
            label={'Ημερομηνία υποβολής του ερωτήματος:'}
            value={questionData?.creation_date}
          />
        </Box>
      );
    } else {
      return <></>;
    }
  };

  const AnswerFromUser = () => {
    if (isAnswered && getRole() === 'admin') {
      return (
        <Box display='inline-flex'>
          <Typography fontWeight={600} mr={2}>
            Απαντήθηκε από :{' '}
          </Typography>
          <Typography>{answerFrom}</Typography>
        </Box>
      );
    } else {
      return <></>;
    }
  };

  return (
    <Box>
      {/* <BackButton /> */}
      {isReseting ? (
        <Loader />
      ) : (
        <FormContainer
          formContext={formContext}
          onSuccess={(data) => {
            handleClick(data);
          }}
        >
          <Box
            sx={{
              display: 'flex',
              mt: 2,
              mb: 7,
              flexDirection: 'column',
              gap: 4,
            }}
          >
            <TitleQuestion />
            <SubtitleQuestion />
            <Box>
              <GovWrapper
                id='category_ids'
                label={id ? 'Κατηγορία' : 'Επιλέξτε μια ή παραπάνω κατηγορία'}
                width={'100%'}
              >
                <>
                  {mappedGroups && (
                    <MultiSelectElement
                      disabled={id}
                      inputProps={{
                        readOnly: id,
                        'aria-labelledby': 'category_ids',
                      }}
                      sx={{ color: 'black !important' }}
                      labelId='category_ids'
                      name='category_ids'
                      options={mappedGroups}
                    />
                  )}
                </>
              </GovWrapper>
              <GovWrapper label={'Θέμα :'} id='topic' width={'100%'}>
                <TextFieldElement
                  id='topic'
                  inputProps={{
                    readOnly: id,
                    'aria-labelledby': 'topic',
                  }}
                  InputProps={{
                    style: { padding: 0, borderRadius: 0, disabled: true },
                  }}
                  name='subject'
                />
              </GovWrapper>
              <GovWrapper
                id='description'
                label={'Κείμενο :'}
                aria-label='Κείμενο'
                width={'100%'}
              >
                <TextFieldElement
                  aria-multiline
                  fullWidth
                  rows='5'
                  inputProps={{
                    readOnly: id,
                    role: 'textbox',
                    'aria-labelledby': 'description',
                    'aria-label': 'Κείμενο',
                    'aria-describedby': 'description',
                  }}
                  InputProps={{
                    style: { padding: 0, borderRadius: 0 },
                  }}
                  name='description'
                  multiline
                />
              </GovWrapper>
            </Box>
          </Box>

          <AnswerFromUser />

          {(getRole() === 'admin' || isAnswered) && (
            <GovWrapper
              label={'Απάντηση :'}
              width={'100%'}
              id='answer'
              aria-label='Κείμενο'
            >
              <TextFieldElement
                multiline
                labelId='answer'
                inputProps={{
                  readOnly: getRole() === 'user',
                  'aria-labelledby': 'answer',
                }}
                InputProps={{
                  style: { padding: 0, borderRadius: 0 },
                }}
                sx={{ mb: 3 }}
                name='answer'
                rows='5'
                margin='normal'
                aria-label='answer'
              />
            </GovWrapper>
          )}
          {(getRole() === 'admin' || !id) && (
            <RoundedButton
              sx={{ my: 2 }}
              type='submit'
              variant={'contained'}
              color={'successLight'}
              onClick={() => {}}
            >
              Υποβολή
            </RoundedButton>
          )}
        </FormContainer>
      )}
    </Box>
  );
};

export default NewQuestion;
