import { Box, Pagination, PaginationItem, Typography } from "@mui/material";
import { gridPageCountSelector, gridPageSelector, gridPaginationRowRangeSelector, useGridApiContext, useGridSelector } from "@mui/x-data-grid";
import PreviousIcon from "./PreviousIcon";
import NextIcon from "./NextIcon";

export function CustomPagination({ totalCount,setSelectedRows }) {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const rowRange = useGridSelector(apiRef, gridPaginationRowRangeSelector
  )
  setSelectedRows && setSelectedRows(apiRef.current.getSelectedRows())
  return (
    <>
{    totalCount > 0 ? <Box sx={{ display: 'flex', border: 0, alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
      <Typography variant="body">Εμφανίζονται <strong>{rowRange?.firstRowIndex + 1}</strong> εώς <strong>{rowRange?.lastRowIndex + 1}</strong> από <strong>{totalCount}</strong> αποτελέσματα</Typography>
      <Pagination
        page={page + 1}
        count={pageCount}
        renderItem={(props2) => <PaginationItem slots={{ previous: PreviousIcon, next: NextIcon }}
          {...props2} disableRipple />}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    </Box> : <Typography> </Typography>
}    </>
    
  );
}
