import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Box, Button, Grid, Typography } from '@mui/material';
import { Link, useLocation, useParams } from 'react-router-dom';
import Details, { mapByRefValue } from '../../Details/Details/Details';
import useAuth from '../../Services/useAuth';
import { useGetRequestById } from '../../Services/useGetRequestById';
import Loader from '../../UIComponents/Loader';
import { communicationData, personalData } from '../../helperFuncs';
import RegistrationSuccess from './RegistrationSuccess';
import { useEffect, useState } from 'react';
import ProtectedRouteViolation from '../AuthPages/ProtectedRouteViolation';
import { downloadFile } from '../../Services/httpCalls';

const RegistrationPreview = () => {
  const { id } = useParams();
  const [isLinkVisible, setIsLinkVisible] = useState(false);
  const { getRole, getUser } = useAuth();
  const location = useLocation();
  const { data: request, isLoading: isRequestLoading } = useGetRequestById(id);
  const mappedPersonalData =
    request &&
    mapByRefValue(
      {
        ...request?.data?.requests[0].user,
        amka: request?.data.requests[0].amka,
      },
      personalData
    );
  const mappedCommunicationData =
    request &&
    mapByRefValue(
      {
        ...request?.data.requests[0],
        email: request?.data.requests[0].user.email,
      },
      communicationData
    );
  useEffect(() => {
    // Delay the appearance of the Link by 4 seconds
    const timer = setTimeout(() => {
      setIsLinkVisible(true);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  const fileDownload = async (request_id, profession_id, file_name) => {
    try {
      await downloadFile(request_id, profession_id, file_name);
    } catch (err) {}
  };
  const mappedProfesionalData = request?.data.requests && [
    {
      name: 'Χωροταξική δραστηριοποίηση',
      refValue: 'spatialActivity',
      id: 0,
      value: request?.data.requests[0]?.municipalities
        .map((item) => item.municipality)
        .join(', '),
    },
    ...request?.data.requests[0]?.professions.flatMap((item, index) => [
      {
        name: 'Ειδικότητα/ Επάγγελμα',
        refValue: 'job',
        id: index + 1,
        value: item.profession,
      },
      {
        name: 'Τεκμηρίωση επαγγέλματος/ειδικότητας',
        refValue: 'proof_description',
        id: index + 2,
        value: item.proof_description,
      },

      {
        name: 'Τίτλος σπουδών',
        refValue: 'profession_proof',
        id: index + 3,
        value:
          item.proof === 4 ? (
            isLinkVisible ? (
              <Typography
                component='a'
                sx={{
                  cursor: 'pointer',
                  color: 'primary.main',
                  fontWeight: 'bold',
                  textDecoration: 'none',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
                onClick={() =>
                  fileDownload(
                    request.data.requests[0]?.id,
                    item?.profession_id,
                    item?.file?.name
                  )
                }
              >
                {item?.file?.name}
              </Typography>
            ) : (
              <Box sx={{ height: '50px' }}>
                <Loader />
              </Box>
            )
          ) : (
            'Δεν απαιτείται'
          ),
      },
    ]),
  ];

  const communicationGroupData =
    request &&
    request?.data.requests[0].communication_groups.map((item) => item.category);

  return (
    <>
      {request?.data?.requests[0].user.id !== getUser()?.sub &&
      getRole() === 'user' &&
      !isRequestLoading ? (
        <ProtectedRouteViolation />
      ) : isRequestLoading ? (
        <Loader />
      ) : (
        <Box>
          {location.pathname.includes('success') && <RegistrationSuccess />}
          <Typography sx={{ my: 3 }}>
            Εγγραφή στο μητρώο απασχολούμενων του πολιτιστικού και δημιουργικού
            τομέα
          </Typography>
          <Typography variant='h1' sx={{ my: 3 }}>
            Προβολή
          </Typography>
          {isRequestLoading ? (
            <Loader />
          ) : (
            <Box>
              <Grid
                container
                direction='row'
                justifyContent='space-between'
                alignItems='center'
              >
                <Grid item xs={6}>
                  <Typography variant='h3' sx={{ my: 3 }}>
                    Πιστοποιημένος χρήστης
                  </Typography>
                  {getRole() === 'admin' ? (
                    <Typography variant='h3' sx={{ my: 3 }}>
                      Κατάσταση
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  xs={6}
                  gap={4}
                  sx={{ display: 'flex', flexDirection: 'column' }}
                >
                  <Typography sx={{ pt: 2 }}>
                    {request?.data.requests[0]?.user?.is_certified === 1 ? (
                      <Typography>Ναι</Typography>
                    ) : (
                      <Link
                        style={{ textDecoration: 'none', color: 'blue' }}
                        to='/registration-form'
                      >
                        Όχι
                      </Link>
                    )}
                  </Typography>

                  {getRole() === 'admin' ? (
                    <Typography>
                      {request?.data.requests[0]?.user?.is_active === 1
                        ? 'Ενεργός'
                        : 'Ανενεργός'}
                    </Typography>
                  ) : null}
                </Grid>
              </Grid>

              <Typography variant='h3' sx={{ my: 3 }}>
                Προσωπικά στοιχεία
              </Typography>
              <Details id={'personalData'} rows={mappedPersonalData} />
              <Typography variant='h3' sx={{ my: 3 }}>
                Στοιχεία επικοινωνίας
              </Typography>
              <Details rows={mappedCommunicationData} />
              <Typography variant='h3' sx={{ my: 3 }}>
                Στοιχεία Δραστηριοποίησης
              </Typography>
              <Details rows={mappedProfesionalData} />
              <Box sx={{ mb: 15 }}>
                <Typography variant='h3' sx={{ my: 3 }}>
                  Ομάδες επικοινωνίας
                </Typography>
                <Details
                  rows={[
                    {
                      id: 0,
                      name: 'Κατηγορία',
                      value: communicationGroupData
                        ? communicationGroupData
                        : 'Δεν ανήκετε σε κάποια ομάδα επικοινωνίας',
                    },
                  ]}
                />
              </Box>
            </Box>
          )}

          <Button
            sx={{ mb: 4 }}
            onClick={() => window.print()}
            variant='contained'
            color={'success'}
            startIcon={<PictureAsPdfIcon />}
          >
            Αποθήκευση
          </Button>
        </Box>
      )}
    </>
  );
};
export default RegistrationPreview;
