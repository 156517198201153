import { Box, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Link, useNavigate } from 'react-router-dom';
import InicialsIcon from '../../UIComponents/InicialsIcon';
import useAuth from '../../Services/useAuth';
import { useGetQuestionByUserId } from '../../Services/useGetQuestionByUserId';
import RoundedButton from '../../UIComponents/Buttons/RoundedButton';
import Loader from '../../UIComponents/Loader';
import { CustomPagination } from '../../UIComponents/CustomPagination';

const QuestionsView = () => {
  const navigate = useNavigate();
  const { getUser } = useAuth();
  const { data: questions, isLoading } = useGetQuestionByUserId(getUser()?.sub);
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Box sx={{ display: 'flex', my: 8, flexDirection: 'column', gap: 6 }}>
          <Typography variant='h2'>Τα ερωτήματα μου</Typography>
          {questions?.data?.length < 1 && (
            <Typography>
              Δεν έχετε καταθέσει κάποιο ερώτημα, εάν θέλετε να καταθέσετε νέο
              ερώτημα πιέστε εδω
            </Typography>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <RoundedButton
              variant={'contained'}
              color={'link'}
              onClick={() => navigate('/new-question')}
            >
              Νέο ερώτημα
            </RoundedButton>
          </Box>
          <Box>
            {questions?.data.questions.length > 0 ? (
              <DataGrid
                initialState={{
                  pagination: { paginationModel: { pageSize: 5 } },
                }}
                pageSizeOptions={[5, 10, 25]}
                pagination
                pageSize={5}
                slots={{ pagination: CustomPagination }}
                slotProps={{
                  pagination: {
                    totalCount: questions?.data.questions.length,
                  },
                }}
                disableRowSelectionOnClick
                columnHeaderHeight={0}
                sx={{
                  '&, [class^=MuiDataGrid]': {
                    border: 'none',
                    overflow: 'hidden',
                    justifyContent: 'flex-start',
                  },
                }}
                rows={questions?.data.questions}
                columns={[
                  {
                    field: 'request',
                    flex: 1,
                    headerName: '',
                    renderCell: (params) => (
                      <Box
                        key={params.id}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 2,
                          marginTop: 1,
                        }}
                      >
                        <Box>
                          <Typography
                            component={Link}
                            to={`/question/${params.id}`}
                            sx={{
                              color: 'black.main',
                              px: 0.5,
                              fontWeight: 'bold',
                            }}
                          >
                            {`Θέμα: ${params.row.subject}`}
                          </Typography>
                          <Typography sx={{ color: 'gray.main' }}>
                            {`${params.row.creation_date} ${
                              params.row.creation_time
                            } ${
                              params.row.answer_date
                                ? 'Απαντήθηκε'
                                : 'Δεν απαντήθηκε'
                            }`}
                          </Typography>
                        </Box>
                      </Box>
                    ),
                  },
                ]}
              />
            ) : (
              <Typography>Δεν έχετε υποβάλει κάποιο ερώτημα.</Typography>
            )}
          </Box>
        </Box>
      )}
    </>
  );
};
export default QuestionsView;
