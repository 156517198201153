import { Box, Typography } from "@mui/material"

const ErrorPage = () => {
    return (
        <>
            <Box sx={{
                maxWidth: '42rem', fontSize: '3rem', lineHeight: '1.25', mh: '3rem', fontWeight: '700'
            }}>
                ??????????, ????????????? ??????.
            </Box>
            <Typography sx={{ my: 4 }}>???????? ????????? ???? ????????.

            </Typography>
        </>
    )
}
export default ErrorPage