import { yupResolver } from "@hookform/resolvers/yup";
import { Box, InputLabel, Typography } from "@mui/material";
import { FormContainer, TextFieldElement, useForm } from "react-hook-form-mui";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { usePostEmail } from "../../Services/usePostEmail";
import RoundedButton from "../../UIComponents/Buttons/RoundedButton";

const EmailFill = () => {
  const { mutateAsync: postEmail, isSuccess } = usePostEmail();
  const navigate = useNavigate();
  const formContext = useForm({
    resolver: yupResolver(
      yup.object().shape({
        email: yup
          .string()
          .email("Παρακαλώ πληκτρολογήστε έγκυρο email(example@gmail.com)")
          .required("Το πεδίο είναι υποχρεωτικό"),
      })
    ),
    defaultValues: { email: "" },
  });
  return (
    <FormContainer
      formContext={formContext}
      onSuccess={async (data) => {
        try {
          await postEmail(data);          
          if (isSuccess) {
            navigate("/confirmation-code");
          }
        } catch (error) {
          console.error('An error occurred:', error);
        }
      }}
    >
      <Typography sx={{ mt: 8 }} variant="h1">
        Καταχωρίστε τη διεύθυνση ηλεκτρονικού ταχυδρομείου (email) στην οποία θα
        λαμβάνετε την επίσημη επικοινωνία από το Πρόγραμμα
      </Typography>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          my: 4,
          pl: 2,
          height: "50px",
          borderLeft: "2px solid",
          borderColor: "border.main",
        }}
      >
        <Typography sx={{ my: 4 }}>
          Η επίσημη επικοινωνία του Προγράμματος θα αποστέλνεται σε αυτήν την
          ηλεκτρονική διεύθυνση.
        </Typography>
      </Box>
      <Box>
        <InputLabel htmlFor="email">
          {"Διεύθυνση ηλεκτρονικού ταχυδρομείου (email)"}
        </InputLabel>
        <TextFieldElement
          id="email"
          aria-label="Διεύθυνση ηλεκτρονικού ταχυδρομείου"
          sx={{ width: "30rem", mt: 1 }}
          InputProps={{ style: { borderRadius: "5px" } }}
          inputProps={{ style: { border: 0, padding: 8 } }}
          type="email"
          fullWidth
          name="email"
        />
      </Box>
      <RoundedButton
        sx={{ my: 4, mb: 10 }}
        type="submit"
        color="link"
        variant="contained"
      >
        Συνέχεια
      </RoundedButton>
    </FormContainer>
  );
};
export default EmailFill;
