import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from '../Services/useAuth';
import { getRequestByAfm } from '../Services/httpCalls';
import { jwtDecode } from 'jwt-decode';

export default function AppDashboard() {
  const { getUser, token } = useAuth();
  const isAdmin = getUser()?.is_admin === 1;
  const isActive = getUser()?.is_active === 1;

  const [anchorEl, setAnchorEl] = useState(null);
  const [isNewUser, setIsNewUser] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  useEffect(() => {
    const checkIsNewUser = async () => {
      if (!token) return false;
      try {
        const resp = await getRequestByAfm(jwtDecode(token)?.afm);
        setIsNewUser(!(resp?.data?.requests?.length > 0));
      } catch (error) {
        console.error('Invalid token:', error);
        return false;
      }
    };
    checkIsNewUser();
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const newUserMenuItems = [
    { label: 'Αλλαγή email', path: '/email' },
    { label: 'Ιστορικό αιτήσεων απογραφής', path: '/request-old-history' },
    { label: 'Τα ερωτήματα μου', path: '/questions' },
    { label: 'Έξοδος', path: '/' },
  ];
  const adminMenuItems = [
    { label: 'Ολες οι αιτήσεις', path: '/request-management' },
    { label: 'Ολα τα ερωτήματα', path: '/questions-management' },
    { label: 'Ομάδες Επικοινωνίας', path: '/groups-management' },
    { label: 'Έξοδος', path: '/' },
  ];
  const userMenuItems = [
    { label: 'Στοιχεία μητρώου', path: '/registry-review' },
    { label: 'Ιστορικό μου', path: '/request-history' },
    { label: 'Ιστορικό αιτήσεων απογραφής', path: '/request-old-history' },
    { label: 'Αλλαγή email', path: '/email' },
    { label: 'Τα ερωτήματα μου', path: '/questions' },
    { label: 'Έξοδος', path: '/' },
  ];
  const inactiveUserMenuItems = [{ label: 'Έξοδος', path: '/' }];

  const getMenu = () => {
    if (isAdmin) {
      return adminMenuItems;
    } else if (isNewUser && isActive) {
      return newUserMenuItems;
    } else if (!isActive && !isAdmin) {
      return inactiveUserMenuItems;
    } else if (isActive && !isAdmin) {
      return userMenuItems;
    }
    return [];
  };

  return (
    <div>
      <IconButton
        id='basic-button'
        aria-label='menu'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon sx={{ color: 'white.main' }} />
      </IconButton>
      {isNewUser !== null && (
        <Menu
          id='basic-menu'
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {getMenu().map((item, index) => (
            <MenuItem
              key={index}
              sx={{ color: 'primary.main' }}
              onClick={() => {
                item.label === 'Έξοδος'
                  ? navigate('/logout-redirect')
                  : navigate(item.path);
                handleClose();
              }}
            >
              {item.label}
            </MenuItem>
          ))}
        </Menu>
      )}
    </div>
  );
}
