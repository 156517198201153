import { getRequestByAfm, isConsent } from '../../Services/httpCalls';
import { useMutation } from '@tanstack/react-query';
import useAuth from '../../Services/useAuth';
import { useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { jwtDecode } from 'jwt-decode';

export function useIsConsent(consent) {
  const { token } = useAuth();
  const navigate = useNavigate();

  const handleConsent = (isNewUser) => {
    if (isNewUser) {
      navigate('/request');
    } else {
      navigate('/registry-review');
    }
  };

  return useMutation({
    mutationKey: [`isConsent`],
    mutationFn: async () => {
      return await isConsent(consent);
    },
    onSuccess: async (response) => {
      try {
        const resp = await getRequestByAfm(jwtDecode(token)?.afm);
        const isNewUser = !(resp?.data?.requests?.length > 0);
        if (!consent) {
          navigate('/registration-form');
        } else {
          handleConsent(isNewUser);
        }

        enqueueSnackbar(response.data.message, {
          variant: 'info',
          autoHideDuration: 2000,
        });
      } catch (error) {
        console.error('Error determining user status:', error);
        enqueueSnackbar('An error occurred while processing your request.', {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    },
    onError: (error) => {
      console.error('Mutation error:', error);
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });
}
