import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import './App.css';

import MainLayout from './UIComponents/MainLayout';

import { lazy, Suspense } from 'react';

import RequestHistory from './Pages/History/RequestHistory';
import RequestOldHistory from './Pages/History/RequestOldHistory';
import SearchRequestSide from './Pages/History/RequestHistorySide';
import RedirectHandler from './Pages/AuthPages/RedirectHandler';
import ProtectedRouteViolation from './Pages/AuthPages/ProtectedRouteViolation';
import AADEConfirm from './Pages/ConfirmationPages/AADEConfirm';
import EmailFill from './Pages/Email/EmailFill';
import ProtectedRoute from './provider/ProtectedRoute';
import ConfirmationCode from './Pages/Email/ConfirmationCode';
import RedirectionPage from './Pages/AuthPages/RedirectionPage';
import NewRequestForm from './Pages/NewRequest/NewRequestForm';
import OldRegistrationPreview from './Pages/Registration/OldRegistrationPreview';
import RegistrationPreview from './Pages/Registration/RegistrationPreview';
import QuestionsView from './Pages/Questions/QuestionsView';
import NewQuestion from './Pages/Questions/NewQuestion';
import ErrorPage from './Pages/AuthPages/ErrorPage';
import UnAuthorizedAdminPage from './Pages/AuthPages/UnAuthorizedAdminPage';
import UnAuthorizedUserPage from './Pages/AuthPages/UnAuthorizedUserPage';
import UserLoginConfirmationPage from './Pages/ConfirmationPages/UserLoginConfirmationPage';
import NoAuthorizedPage from './Pages/AuthPages/NoAuthorizedPage';
import InactiveUserPage from './Pages/AuthPages/InactiveUserPage';
import RegistryReview from './Pages/UserInfo/RegistryReview';
import DetailsPreview from './Pages/UserInfo/DetailsPreview';
import RegistrationForm from './Pages/ConfirmationPages/RegistrationForm';
const GroupsManagement = lazy(() =>
  import('./Pages/Management/GroupsManagement')
);

const QuestionsManagement = lazy(() =>
  import('./Pages/Management/QuestionsManagement')
);

const RequestManagement = lazy(() =>
  import('./Pages/Management/RequestManagement')
);
export const router = createBrowserRouter([
  {
    path: '/',
    children: [
      {
        index: true,
        element: <MainLayout main={<NoAuthorizedPage />} />,
      },
      {
        path: 'user-login-confirmation',
        element: <MainLayout main={<UserLoginConfirmationPage />} />,
      },

      {
        path: '/route-violation',
        element: <MainLayout main={<ProtectedRouteViolation />} />,
      },
      {
        path: '/logout-redirect',
        element: <MainLayout main={<RedirectHandler />} />,
      },
      {
        path: 'user-error',
        element: <MainLayout main={<UnAuthorizedUserPage />} />,
      },
      {
        path: 'admin-error',
        element: <MainLayout main={<UnAuthorizedAdminPage />} />,
      },
      {
        path: 'inactive-user',
        element: <MainLayout main={<InactiveUserPage />} />,
      },
      {
        path: 'confirm',
        element: (
          <ProtectedRoute isAdmin={0}>
            <MainLayout main={<AADEConfirm />} />
          </ProtectedRoute>
        ),
      },
      {
        path: 'redirect',
        element: <MainLayout main={<RedirectionPage />} />,
      },
      {
        path: 'email',
        element: (
          <ProtectedRoute isAdmin={0}>
            <MainLayout main={<EmailFill />} />
          </ProtectedRoute>
        ),
      },
      {
        path: 'confirmation-code',
        element: (
          <ProtectedRoute isAdmin={0}>
            <MainLayout main={<ConfirmationCode />} />
          </ProtectedRoute>
        ),
      },
      {
        path: 'details-preview',
        element: (
          <ProtectedRoute isAdmin={0}>
            <MainLayout main={<DetailsPreview />} />
          </ProtectedRoute>
        ),
      },
      {
        path: 'registry-review',
        element: (
          <ProtectedRoute isAdmin={0}>
            <MainLayout main={<RegistryReview />} />
          </ProtectedRoute>
        ),
      },
      {
        path: 'registration-form',
        element: (
          <ProtectedRoute isAdmin={0}>
            <MainLayout main={<RegistrationForm />} />
          </ProtectedRoute>
        ),
      },
      {
        path: 'request',
        element: (
          <ProtectedRoute isAdmin={0}>
            <MainLayout main={<NewRequestForm />} />
          </ProtectedRoute>
        ),
      },
      {
        path: 'request/:id',
        element: (
          <ProtectedRoute isAdmin={0}>
            <MainLayout main={<NewRequestForm />} />
          </ProtectedRoute>
        ),
      },
      {
        path: 'request-history',
        element: (
          <MainLayout
            main={
              <ProtectedRoute isAdmin={0}>
                <RequestHistory />
              </ProtectedRoute>
            }
            side={
              <ProtectedRoute isAdmin={0}>
                <SearchRequestSide />
              </ProtectedRoute>
            }
          />
        ),
      },
      {
        path: 'request-old-history',
        element: (
          <MainLayout
            main={
              <ProtectedRoute isAdmin={0}>
                <RequestOldHistory />
              </ProtectedRoute>
            }
            side={
              <ProtectedRoute isAdmin={0}>
                <SearchRequestSide />
              </ProtectedRoute>
            }
          />
        ),
      },
      {
        path: 'old-request-review/:id',
        element: <MainLayout main={<OldRegistrationPreview />} />,
      },
      {
        path: '/request-review/:id',
        element: <MainLayout main={<RegistrationPreview />} />,
      },
      {
        path: '/request-review/:id/success-new',
        element: (
          <MainLayout
            main={
              <ProtectedRoute isAdmin={0}>
                <RegistrationPreview />
              </ProtectedRoute>
            }
          />
        ),
      },
      {
        path: '/request-review/:id/success-update',
        element: (
          <MainLayout
            main={
              <ProtectedRoute isAdmin={0}>
                <RegistrationPreview />
              </ProtectedRoute>
            }
          />
        ),
      },
      {
        path: 'questions',
        element: <MainLayout main={<QuestionsView />} />,
      },
      {
        path: 'question/:id',
        element: <MainLayout main={<NewQuestion />} />,
      },
      {
        path: 'new-question',
        element: <MainLayout main={<NewQuestion />} />,
      },
      {
        path: 'request-management',
        element: (
          <MainLayout
            main={
              <Suspense>
                <ProtectedRoute isAdmin={1}>
                  <RequestManagement />
                </ProtectedRoute>
              </Suspense>
            }
          />
        ),
      },
      {
        path: 'questions-management',
        element: (
          <MainLayout
            main={
              <Suspense>
                <ProtectedRoute isAdmin={1}>
                  <QuestionsManagement />
                </ProtectedRoute>
              </Suspense>
            }
          />
        ),
      },
      {
        path: 'groups-management',
        element: (
          <MainLayout
            main={
              <Suspense>
                <ProtectedRoute isAdmin={1}>
                  <GroupsManagement />
                </ProtectedRoute>
              </Suspense>
            }
          />
        ),
      },
      { path: '*', element: <MainLayout main={<ErrorPage />} /> },
    ],
  },
]);
function App() {
  return <RouterProvider router={router}></RouterProvider>;
}

export default App;
