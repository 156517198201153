import { Box, InputLabel } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import React from "react";
const GovWrapper = ({
  label,
  width,
  children,
  id,
  disabled = false,
  defaultValue,
}) => {
  const govChildren = children
    ? React.cloneElement(children, { disabled: disabled })
    : null;
  return (
    <Box
      aria-label={label}
      sx={{
        mt: 2,
        display: "flex",
        width: width,
        gap: 1,
        flexDirection: "column",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <InputLabel
          defaultValue={defaultValue}
          id={id}
          aria-label={label}
          htmlFor={id}
          sx={{ fontWeight: "bold" }}
        >
          {label}
        </InputLabel>
        {disabled && <LockIcon color="link" fontSize="small" />}
      </Box>
      {govChildren}
    </Box>
  );
};
export default GovWrapper;
