import axios from 'axios';


const HttpMethods = {
  GET: 'GET',
  POST: 'POST',
  DELETE: 'DELETE',
  PUT: 'PUT',
  PATCH: 'PATCH'
};

const _axios = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}`,
  
});
const AxiosInterceptorConfigure = () => {
  _axios.interceptors.request.use((config) => {
    const token = localStorage.getItem('token'); 
    if (!token) {
      // Optionally handle when there's no token, e.g., redirect to login page
      console.warn("No token found! Redirecting to login.");
      // You can throw an error or return a rejected promise to stop the request
      return Promise.reject("No token found");
    }
    
    // If token exists, add it to the Authorization header
    if (token) {
      config.headers.Authorization = `Bearer ${token.replace(/"/g, '')}`;
    }
    return config;
  }, (error) => {
    // Handle errors in request configuration
    return Promise.reject(error);
  });
};

const getAxiosClient = () => _axios;

const HttpService = {
  HttpMethods,
  configure: AxiosInterceptorConfigure,
  getAxiosClient,
};

export {_axios}
export default HttpService;