import { useQuery } from "@tanstack/react-query";
import { getQuestionByUserId } from "./httpCalls";
import { enqueueSnackbar } from "notistack";

export function useGetQuestionByUserId(id) {
    return useQuery({
        queryFn: () => getQuestionByUserId(id),
        queryKey: [`get_question`],
        onSuccess: () => {
            enqueueSnackbar('????????', { variant: 'success', autoHideDuration:2000 });
          },
          onError: (error) => {
            enqueueSnackbar(error?.response?.data?.message || '????????', { variant: 'error', autoHideDuration:2000 });
          }
    });
}
