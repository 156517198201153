import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Details, { mapByRefValue } from '../../Details/Details/Details';
import { getOldRequestById } from '../../Services/httpCalls';
import Loader from '../../UIComponents/Loader';
import { personalData } from '../../helperFuncs';
import useAuth from '../../Services/useAuth';
import RoundedButton from '../../UIComponents/Buttons/RoundedButton';
import PDFViewer from '../../UIComponents/PDFViewer';

const OldRegistrationPreview = () => {
  const [oldRequest, setOldRequest] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [fileContent, setFileContent] = useState();
  const [openFile, setOpenFile] = useState(false);
  const { getUser } = useAuth();
  const navigate = useNavigate();
  const { id } = useParams();
  useEffect(() => {
    const fetchOldRequests = async () => {
      setIsLoading(true);
      try {
        const response = await getOldRequestById(id);
        if (response.data.old_requests[0].afm !== getUser()?.afm) {
          navigate('/route-violation');
        }
        setOldRequest(response?.data?.old_requests[0]);
      } catch (error) {
        console.error('Failed to fetch old requests', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOldRequests();
  }, [id]);

  const mappedBasicData = [
    {
      name: 'Αρ.πρωτοκολλου',
      refValue: 'protocol',
      id: 1,
      value: oldRequest?.protocol,
    },
    {
      name: 'Ημερομηνία υποβολής',
      refValue: 'creation_date',
      id: 1,
      value: oldRequest?.submit_date,
    },
  ];
  const mappedPersonalData =
    oldRequest && mapByRefValue(oldRequest, personalData);

  const mappedCommunicationData = [
    {
      name: 'Εmail',
      refValue: 'email',
      id: 1,
      value: oldRequest?.email,
    },
    {
      name: 'Κινητό τηλέφωνο',
      refValue: 'mobile',
      id: 1,
      value: oldRequest?.mobile,
    },
  ];
  const mappedProfesionalData = [
    {
      name: 'Ειδικότητα/ Επάγγελμα',
      refValue: 'profession',
      id: 1,
      value: oldRequest?.speacialty_description,
    },
    {
      name: 'Τεκμηρίωση επαγγέλματος/ειδικότητας',
      refValue: 'degree',
      id: 1,
      value: (
        <RoundedButton
          color='success'
          variant='contained'
          onClick={() => {
            setFileContent(oldRequest?.file_content);
            setOpenFile(true);
          }}
        >
          {' '}
          {oldRequest?.file_name}
        </RoundedButton>
      ),
    },
  ];
  return (
    <Box>
      {/* <BackButton /> */}
      <Typography sx={{ my: 3 }}>
        Υπεύθυνη δήλωση απογραφής στο μητρώο καλλιτεχνών, δημιουργών και
        επαγγελματιών της τέχνης και του πολιτισμού
      </Typography>
      <Typography variant='h1' sx={{ my: 3 }}>
        Προβολή
      </Typography>
      {isLoading ? (
        <Loader />
      ) : (
        <Box>
          <Typography variant='h3' sx={{ my: 3 }}>
            Βασικά στοιχεία αίτησης
          </Typography>
          <Details id={'requestData'} rows={mappedBasicData} />
          <Typography variant='h3' sx={{ my: 3 }}>
            Προσωπικά στοιχεία
          </Typography>
          <Details id={'personalData'} rows={mappedPersonalData} />
          <Typography variant='h3' sx={{ my: 3 }}>
            Στοιχεία επικοινωνίας
          </Typography>
          <Details id={'CommunicationData'} rows={mappedCommunicationData} />
          <Typography variant='h3' sx={{ my: 3 }}>
            Στοιχεία Δραστηριοποίησης
          </Typography>
          <Details id={'ProfesionalData'} rows={mappedProfesionalData} />
        </Box>
      )}
      {openFile && (
        <PDFViewer
          data={fileContent}
          setIsShownModal={setOpenFile}
          open={openFile}
        />
      )}
    </Box>
  );
};
export default OldRegistrationPreview;
