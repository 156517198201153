import BorderColorIcon from '@mui/icons-material/BorderColor';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import { Box, Chip, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import { format, parse } from 'date-fns';

export const formatDateFNS = (date) => {
  if (!date) return null;
  return format(date, 'yyyy-MM-dd');
};

export const parseDateFns = (date) => {
  if (!date) return null;
  return new Date(date);
};
export const transformToFormData = (data, fileNames, files) => {
  const formRequestData = new FormData();
  formRequestData?.append('amka', data.amka);
  formRequestData?.append('phone_number', data.phone_number);
  if (data.municipalities) {
    data.municipalities?.forEach((municipality, index) => {
      formRequestData.append(`municipalities[${index}]`, municipality);
    });
  }
  if (data.municipality_ids) {
    data.municipality_ids?.forEach((municipality, index) => {
      formRequestData.append(
        `municipality_ids[${index}]`,
        municipality?.value || municipality
      );
    });
  }
  data.activities?.forEach((activity, index) => {
    formRequestData.append(
      `activities[${index}][profession_id]`,
      activity.profession_id
    );
    formRequestData.append(
      `activities[${index}][profession_proof_id]`,
      +activity.profession_proof_id
    );
    if (fileNames && Object.values(fileNames).length > 0) {
      formRequestData.append(
        `activities[${index}][fileName]`,
        fileNames[index]
      );
    }
  });
  if (files.length > 0) {
    for (const file of files) {
      formRequestData.append('files', file);
    }
  }
  return formRequestData;
};

export function base64ToFile(base64String, filename) {
  const byteEncoded = atob(base64String);
  let n = byteEncoded.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = byteEncoded.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: 'application/pdf' });
}

export const personalData = [
  {
    name: 'Όνομα',
    refValue: 'first_name',
  },
  {
    name: 'Επώνυμο',
    refValue: 'last_name',
  },
  {
    name: 'Πατρώνυμο',
    refValue: 'fathers_name',
  },
  {
    name: 'Μητρώνυμο',
    refValue: 'mothers_name',
  },
  {
    name: 'ΑΦΜ',
    refValue: 'afm',
  },
  {
    name: 'ΑΜΚΑ',
    refValue: 'amka',
  },
];
export const questions = [
  {
    subject: 'Άδεια ασκήσεως επαγγέλματος',
    date: '5/4/2022 10:13:37',
    status: 'απαντήθηκε',
  },
];
export const communicationData = [
  {
    name: 'Εmail',
    refValue: 'email',
  },
  {
    name: 'Κινητό τηλέφωνο',
    refValue: 'phone_number',
  },
  {
    name: 'Ημερομηνία εγγραφής',
    refValue: 'creation_date',
  },
  {
    name: 'Ημερομηνία τελευταίας μεταβολής',
    refValue: 'last_updated',
  },
];

export const oldRequest = {
  basicInfo: [
    {
      name: 'Αρ.πρωτοκολλου',
      refValue: 'protocol',
    },
    {
      name: 'Ημερομηνία υποβολής',
      refValue: 'creation_date',
    },
    {
      name: 'Εκπρόθεσμη',
      expired: 'expired',
    },
  ],
  communicationData: [
    {
      name: 'Εmail',
      refValue: 'email',
    },
    {
      name: 'Κινητό τηλέφωνο',
      refValue: 'phone_number',
    },
  ],
};

export const dateFormat = (date) => {
  if (date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  }
  return null;
};

export const groupsColumns = (handleClick) => [
  {
    field: 'category',
    flex: 1,
    headerName: 'Κατηγορία',
  },
  {
    field: 'is_active',
    flex: 1,
    headerName: 'Κατάσταση',
    renderCell: (params) => (
      <Chip
        color={params.row.is_active === 1 ? 'success' : 'error'}
        variant='outlined'
        icon={params.row.is_active === 1 ? <CheckIcon /> : <CloseIcon />}
        label={params.row.is_active === 1 ? 'Ενεργή' : 'Ανενεργή'}
      />
    ),
  },
  {
    field: 'action',
    flex: 1,
    headerAlign: 'center',
    headerName: 'Ενέργειες',
    renderCell: (params) => (
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          width: '100%',
          justifyContent: 'center',
        }}
      >
        <BorderColorIcon
          sx={{ cursor: 'pointer', color: 'primary.dark' }}
          onClick={() => handleClick('edit', params.row)}
        />
        {params.row.is_active === 1 ? (
          <ToggleOffIcon
            fontSize='large'
            color='success'
            sx={{ cursor: 'pointer' }}
            onClick={() => handleClick('deactivate', params.row)}
          />
        ) : (
          <ToggleOnIcon
            color='error'
            fontSize='large'
            sx={{ cursor: 'pointer' }}
            onClick={() => handleClick('activate', params.row)}
          />
        )}
      </Box>
    ),
  },
];
export const questionColumns = [
  {
    field: 'subject',
    flex: 1,
    headerName: 'Θέμα',
    renderCell: (params) => (
      <Link to={`/question/${params.row.id}`}>{params.row.subject}</Link>
    ),
  },
  {
    field: 'creation_date',
    flex: 1,
    headerName: 'Ημ/νία Υποβολής',
  },
  {
    field: 'answer_date',
    flex: 1,
    headerName: 'Ημ/νία Απάντησης',
  },
  {
    field: 'category',
    flex: 1,
    headerName: 'Κατηγορία',
    renderCell: (params) => (
      <Typography>
        {params.row.question_categories.map((item) => item.category).join(', ')}
      </Typography>
    ),
  },
  {
    field: 'first_name',
    flex: 1,
    headerName: 'Όνομα',
  },
  {
    field: 'last_name',
    flex: 1,
    headerName: 'Επώνυμο',
  },
];
export const userColumns = (handleClick) => [
  {
    field: 'afm',
    flex: 0.8,
    headerName: 'ΑΦΜ',
    renderCell: (params) => (
      <Link to={`/request-review/${params.row.registry_id}`}>
        {params.row.afm}
      </Link>
    ),
  },
  {
    field: 'first_name',
    flex: 0.8,
    headerName: 'Όνομα',
  },
  {
    field: 'last_name',
    headerName: 'Επώνυμο',
  },
  {
    field: 'lastSubscription',
    flex: 0.8,
    headerName: 'Ημ/νία Αίτησης Εγγραφής',
    type: 'date',
    valueGetter: (params) => {
      const parsedDate = parse(params.value, 'dd/MM/yyyy', new Date());
      return isNaN(parsedDate.getTime()) ? null : parsedDate;
    },
    sortComparator: (date1, date2) => {
      return date1 - date2;
    },
    renderCell: (params) => {
      if (!params.value) return <Typography>—</Typography>;

      const formattedDate = format(params.value, 'dd/MM/yyyy');

      return <Typography variant='body2'>{formattedDate}</Typography>;
    },
    sortable: true,
  },
  {
    field: 'fathers_name',
    flex: 0.8,
    headerName: 'Πατρώνυμο',
  },
  {
    field: 'email',
    flex: 1.5,
    headerName: 'Email',
  },
  {
    field: 'is_certified',
    flex: 1.5,
    headerName: 'Πιστοποιημένος ',
    renderCell: (params) => (
      <Typography>{params.row.is_certified === 1 ? 'Ναι' : 'Όχι'}</Typography>
    ),
  },
  {
    flex: 0.3,
    headerName: '',
    renderCell: (params) =>
      params.row.is_active === 1 ? (
        <ToggleOffIcon
          fontSize='large'
          color='success'
          sx={{ cursor: 'pointer' }}
          onClick={() => handleClick('deactivate', params.row)}
        />
      ) : (
        <ToggleOnIcon
          color='error'
          fontSize='large'
          sx={{ cursor: 'pointer' }}
          onClick={() => handleClick('activate', params.row)}
        />
      ),
  },
];
export const erganiData = [
  {
    name: 'Επωνυμία εργοδότη',
    refValue: 'nameOfEmployer',
  },
  {
    name: 'ΑΦΜ εργοδότη',
    refValue: 'employerTaxId',
  },
  {
    name: 'Στοιχεία αναγγελίας',
    refValue: 'listingDetails',
  },
  {
    name: 'Τύπος αναγγελίας',
    refValue: 'advertisementType',
  },
  {
    name: 'Ημερομηνία γεγονότος',
    refValue: 'dateOfEvent',
  },
  {
    name: 'Καθεστώς απασχόλησης',
    refValue: 'employmentStatus',
  },
  {
    name: 'Ωρες εβδομαδιαίως',
    refValue: 'hoursPerWeek',
  },
  {
    name: 'Ειδικότητα ΣΤΕΠ92',
    refValue: 'step92Specialty',
  },
  {
    name: 'Περίοδος αναφοράς ',
    refValue: 'referencePeriod',
  },
  {
    name: 'Απασχόληση',
    refValue: 'employment',
  },
  {
    name: 'Στοιχεία υπάρχουσας απογραφής',
    refValue: 'existingInventoryData',
  },
];

export const efkaData = [
  {
    name: 'Περίοδος αναφοράς',
    refValue: 'referencePeriod',
  },
  {
    name: 'Αριθμός μητρώου εργοδότη',
    refValue: 'employerAM',
  },
  {
    name: 'Αριθμός μητρώου ασφαλισμένου',
    refValue: 'insuredNumber',
  },
  {
    name: 'Αριθμός φορολογικού μητρώου',
    refValue: 'taxId',
  },
  {
    name: 'ΑΜΚΑ',
    refValue: 'amka',
  },
  {
    name: 'Ημέρες απασχόλησης',
    refValue: 'employmentDays',
  },
  {
    name: 'Ημέρες ασφάλισης',
    refValue: 'insuranceDays',
  },
  {
    name: 'ΚΑΔ ΙΚΑ',
    refValue: 'kadIKA',
  },
  {
    name: 'Ειδικότητα',
    refValue: 'specialty',
  },
  {
    name: 'Κωδικός πακέτου καλύψεων',
    refValue: 'coverageCode',
  },
];

export const aadeData = [
  {
    name: 'Στοιχεία δραστηριότητας',
    refValue: 'activityDetails',
  },
  {
    name: 'Ένδειξη επιτηδευματία',
    refValue: 'indicationPretentious',
  },
  {
    name: 'Κωδικός δραστηριότητας',
    refValue: 'activityCode',
  },
  {
    name: 'Περιγραφή δραστηριότητας',
    refValue: 'activityDescription',
  },
  {
    name: 'Κύρια / Δευτερεύουσα',
    refValue: 'mainSecondary',
  },
  {
    name: 'Ημερομηνία έναρξης',
    refValue: 'startDate',
  },
  {
    name: 'Ετήσια εισοδήματα',
    refValue: 'annualIncomes',
  },
];

export const tekaDAta = [
  {
    name: 'Νέος ασφαλισμένος μισθωτός',
    refValue: 'newInsuredEmployee',
  },
  {
    name: 'Ασφαλιστική κατάσταση',
    refValue: 'insuranceStatus',
  },
  {
    name: 'Μέρες ασφάλισης ανά έτος',
    refValue: 'yearlyInsuranceDays',
  },
];

export const newQuestionDefaultValues = {
  category_ids: [],
  subject: '',
  description: '',
  answer: '',
};
export const getLabels = (idsAndLabels, ids) => {
  const matchedLabels = [];
  const idToLabelMap = new Map(
    idsAndLabels.map((item) => [item.id, item.label])
  );

  ids?.forEach((id) => {
    if (idToLabelMap.has(id)) {
      matchedLabels.push(idToLabelMap.get(id));
    } else {
      matchedLabels.push(null);
    }
  });

  return matchedLabels;
};

export const getSelectedGroupsIds = (data) => {
  return Object.keys(data)
    .filter((key) => data[key] === true)
    .map((i) => parseInt(i));
};

export function splitAtLastBackslash(inputString) {
  const lastBackslashIndex = inputString.lastIndexOf('\\');
  if (lastBackslashIndex === -1) {
    return inputString;
  }
  return inputString.substring(lastBackslashIndex + 1);
}

export const buildTreeData = (municipalities) => {
  const regions = {};
  municipalities?.forEach((municipality) => {
    const {
      region_name,
      regional_unit_name,
      municipality_name,
      municipality_id,
    } = municipality;
    if (!regions[region_name]) {
      regions[region_name] = {};
    }
    if (!regions[region_name][regional_unit_name]) {
      regions[region_name][regional_unit_name] = [];
    }
    regions[region_name][regional_unit_name].push({
      label: municipality_name,
      value: municipality_id,
    });
  });

  const treeData = Object.keys(regions).map((region) => ({
    label: region,
    value: region,
    children: Object.keys(regions[region]).map((regionalUnit) => ({
      label: regionalUnit,
      value: regionalUnit,
      children: regions[region][regionalUnit],
    })),
  }));

  return treeData;
};

export const dummyUser = {
  data: {
    ergani: {
      nameOfEmployer: 'Company XYZ',
      employerTaxId: '123456789',
      listingDetails: 'Details about the listing',
      advertisementType: 'Full-time',
      dateOfEvent: '2023-08-27',
      employmentStatus: 'Active',
      hoursPerWeek: 40,
      step92Specialty: 'Software Developer',
      referencePeriod: '2023 Q1',
      employment: 'Employed',
      existingInventoryData: 'Some existing data',
    },
    efka: {
      referencePeriod: '2023 Q1',
      employerAM: '987654321',
      insuredNumber: '654321',
      taxId: '123456789',
      amka: '12345678901',
      employmentDays: 20,
      insuranceDays: 20,
      kadIKA: '1234',
      specialty: 'Software Developer',
      coverageCode: '5678',
    },
    aade: {
      activityDetails: 'Software Development',
      indicationPretentious: 'Yes',
      activityCode: '6201',
      activityDescription: 'Computer programming activities',
      mainSecondary: 'Main',
      startDate: '2020-01-01',
      annualIncomes: '50000',
    },
    teka: {
      newInsuredEmployee: 'Yes',
      insuranceStatus: 'Active',
      yearlyInsuranceDays: 365,
    },
  },
};

export const ErganiFields = [
  { label: 'Ειδικότητα (ΣΤΕΠ92)', key: 'specialty' },
  { label: 'ΑΦΜ εργοδότη', key: 'employer_afm' },
  { label: 'Περίοδος', key: 'period' },
  { label: 'Ημέρες Εργασίας', key: 'working_days' },
];

export const EfkaFields = [
  { label: 'Ειδος Δραστηριότητας Εργοδότη', key: 'activity' },
  { label: 'Αριθμός Μητρώου Εργοδότη', key: 'employer_am' },
  { label: 'Επωνυμία Εργοδότη', key: 'employer_name' },
  { label: 'Περίοδος', key: 'period' },
  { label: 'Ημέρες Ασφάλισης', key: 'working_days' },
  { label: 'Ειδικότητα', key: 'profession_description' },
];
