import { useQuery } from "@tanstack/react-query";
import { getUser } from "./httpCalls";
import { enqueueSnackbar } from "notistack";

export function useGetUser(afm) {
    return useQuery({
        queryFn: () => getUser(afm),
        queryKey: [`useGetUser`],
        onSuccess: () => {
            enqueueSnackbar('????????', { variant: 'success' , autoHideDuration:2000});
          },
          onError: (error) => {
            enqueueSnackbar(error?.response?.data?.message || '????????', { variant: 'error' , autoHideDuration:2000});
          }
    });
}
