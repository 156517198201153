import { AppBar, Typography, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";
import useAuth from "../Services/useAuth";
import logo from "../assets/favicon.svg";
import nextGeneration from "../assets/greece2.png";
import AppDashboard from "./AppDashboard";
import { useWindowSize } from "@uidotdev/usehooks";

export default function MenuAppBar() {
  const { getUser } = useAuth();
  const theme = useTheme();
  const { width } = useWindowSize();
  const isMobileView = width <= 915;

  return (
    <AppBar
      position="static"
      sx={{
        height: isMobileView ? "auto" : "72px", // Adjust height for mobile view
        borderBottom: `8px solid ${theme.palette.link.secondary}`,
      }}
    >
      <Toolbar
        disableGutters
        sx={{
          justifyContent: "space-between",
          display: "flex",
          alignItems: isMobileView ? "flex-start" : "center", // Align items flex-start in mobile
          flexDirection: isMobileView ? "column" : "row", // Stack in column for mobile
          padding: "0rem 1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: isMobileView ? "column" : "row", // Stack elements vertically in mobile
            pl: 2,
            justifyContent: isMobileView ? "center" : "flex-start",
            width: "100%",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <img height="50px" src={logo} alt="" />
            <Box
              sx={{
                width: "200px",
                borderLeft: "1px solid",
                borderRight: "1px solid",
                px: 1,
                borderColor: "white",
                mr: isMobileView ? 0 : 2,
                mt: isMobileView ? 1 : 0,
              }}
            >
              <Typography
                sx={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "14px",
                  width: "200px",
                }}
              >
                Ψηφιακή Αίτηση Εγγραφής
              </Typography>

              <Typography
                sx={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "10px",
                  width: "200px",
                }}
              >
                στο Μητρώο Απασχολούμενων στον Πολιτιστικό και Δημιουργικό Τομέα
              </Typography>
            </Box>
          </Box>

          <img height="60px" src={nextGeneration} alt="" />
        </Box>

        {getUser() && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              justifyContent:'flex-end',
              mx: 2,
              gap: 1,
              mt: isMobileView ? 2 : 0,
              width: isMobileView ? "100%" : "auto",
            }}
          >
            <Typography
              sx={{
                fontWeight: "normal",
                fontSize: "14px",
                whiteSpace: "nowrap",
              }}
            >
              Έχετε συνδεθεί ως
            </Typography>
            <Typography
              sx={{
                ml: isMobileView ? 0 : 1,
                mt: isMobileView ? 1 : 0,
                fontWeight: "bold",
                width: isMobileView ? "150px" : "auto",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {getUser()?.first_name + " " + getUser()?.last_name}
            </Typography>
            <AppDashboard />
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
}
