import { useQuery } from "@tanstack/react-query";
import { getJobs } from "./httpCalls";
import { enqueueSnackbar } from "notistack";

export function useGetJobs() {
    return useQuery({
        queryFn: () => getJobs(),
        queryKey: [`get_jobs`],
        onSuccess: () => {
            enqueueSnackbar('????????', { variant: 'success', autoHideDuration:2000 });
          },
          onError: (error) => {
            enqueueSnackbar(error?.response?.data?.message || '????????', { variant: 'error' , autoHideDuration:2000});
          }
    });
}
