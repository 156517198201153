import { Typography, Box, Divider } from '@mui/material';
import { useWindowSize } from '@uidotdev/usehooks';

export default function AADEdetails() {
  const { width } = useWindowSize();
  const isMobileView = width < 1023;
  const isIPadView = width >= 1024 && width <= 1224;
  const isLaptopView = width >= 1225 && width <= 1540;

  const gridColumnWidth = () => {
    let gridTemplateColumns = '550px 1fr';
    if (isMobileView) {
      gridTemplateColumns = '250px 1fr';
    } else if (isLaptopView) {
      gridTemplateColumns = '410px 1fr';
    } else if (isIPadView) {
      gridTemplateColumns = '210px 1fr';
    } else {
      gridTemplateColumns = '550px 1fr';
    }

    return gridTemplateColumns;
  };

  const DetailRow = ({ label, value }) => (
    <>
      <Box
        display='grid'
        gridTemplateColumns={gridColumnWidth()}
        alignItems='center'
        my={1}
      >
        <Typography fontWeight={600}>{label}</Typography>
        <Typography>{value}</Typography>
      </Box>
      <Divider />
    </>
  );

  return (
    <>
      <DetailRow label={'Ημερομηνία Έναρξης'} value={'25/01/2019'} />
      <DetailRow
        label={'Κύρια Δραστηριότητα'}
        value={'85521102 - Υπηρεσίες καθηγητή χορού'}
      />
      <Box my={1}>
        <Typography fontWeight={600}>Δραστηριότητες</Typography>
        <Typography my={1}>
          85521102 - Υπηρεσίες καθηγητή χορού [Κύρια] από 15/01/2019
        </Typography>
        <Divider />
        <Typography my={1}>
          85521201 - Υπηρεσίες καθηγητή μουσικής [Δευτερεύουσα] από 18/02/2021
        </Typography>
      </Box>
    </>
  );
}
