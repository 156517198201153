import { Box, Button, Grid } from "@mui/material";
import React, { useState } from "react";
import Footer from "./Footer";
import MenuAppBar from "./MenuAppBar";
import { useWindowSize } from "@uidotdev/usehooks";

const MainLayout = ({ main, side }) => {
  const { width } = useWindowSize();
  const isMobileView = width <= 915;
  const [isAuthenticated] = useState(true);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        width: "100%",
        overflowX: "hidden", 
      }}
    >
      <MenuAppBar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1, 
          width: "100%", 
        }}
      >
        <Box
          sx={{
            flex: 1,
            overflowY: "auto",
            width: "100%", 
          }}
        >
          {isAuthenticated ? (
            <Grid
              container
              spacing={isMobileView ? 0 : 5}
              sx={{
                pl: isMobileView ? 2 : 20,
                width: "100%",
                margin: 0, 
              }}
            >
              <Grid
                item
                xs={12}
                md={isMobileView ? 10 : 9}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  px: isMobileView ? 2 : 20, 
                  width: "100%", 
                }}
              >
                {main}
              </Grid>
              {!isMobileView && side && (
                <Grid item xs={12} md={3} sx={{ width: "100%" }}>
                  {side ? side : null}
                </Grid>
              )}
            </Grid>
          ) : (
            <Button onClick={() => {}}>Login</Button>
          )}
        </Box>
        <Footer />
      </Box>
    </Box>
  );
};

export default MainLayout;
